'use strict';

// グローバル領域にjQueryを読み込みます。
// 下層ページなどでjQueryプラグインを使う場合はスクリプトの読み込み順序に注意してください。
const $ = require('jquery');
require('intersection-observer');
window.$ = $;
window.jQuery = $;

// グローバル領域にmomentを読み込みます。
// ファイルサイズ削減のためにwebpackのconfigにて言語ファイルをIgnoreしてあります。
const moment = require('moment');
require('moment/locale/ja'); // 言語ファイルを個別に読み込む
moment.locale('ja');
window.moment = moment;

// スムーズスクロールを読み込みます。
// グローバル領域に読み込みます。発火タイミングは各ページに任せます。
// https://ejointjp.github.io/smooziee.js
import {smooziee} from './_smooziee.es6';
//window.smooziee = smooziee;
if (
  location.pathname === '/cases/index.html' ||
  location.pathname === '/cases/' ||
  location.pathname === '/' ||
  location.pathname === '/index.html'
) {
  // AjaxでDOMがレンダリングされるベージは、スクロール位置がずれるため個別にsmoozieeを呼び出します...
} else {
  $(function () {
    smooziee($, window, document, undefined);
    $.smooziee({
      ignore: '.no-scroll', // スクロールを除外するクラス
      //offset: 0, // 移動先のオフセット
      offset: $('#header').innerHeight() * -1,
      otherPageScroll: true, // 他ページへのリンクの際のスクロール
      scrollKey: 'scroll_id', // ハッシュタグの代わりに使用するクエリのキー
      speed: 1000, // スクロール開始から終了までの時間
      urlHistory: 'replace', // URL書き換え時にhistoryを追加するか上書きするか
      urlParam: 'hash' // URLの書き換え hash, none, default
    });
  });
}

//drawer-menu
$(function () {
  $('.drawer_menu').click(function () {
    $('.gl_header--area').toggleClass('open');
    $(this).toggleClass('active');
    $('.gl_header--logo_tokyo, .gl_header--logo_middle, .gl_header--icon_facebook, .gl_header--wrap, .gl_header--logo').toggleClass('change');
    return false;
  });
});

// iwasaki まずは一度ご相談くださいのエフェクト処理
$(function () {
  const pleaseContent = document.querySelector('.m-please_contact');
  if(!pleaseContent) return;
  const options = {
    root: null,
    rootMargin: '-50% 0px',
    threshold: 0
  };
  const observer = new IntersectionObserver(function (items) {
    items.forEach(function (item) {
      if (item.isIntersecting) {
        item.target.classList.add('is_show');
      }
    });
  }, options);
  observer.observe(pleaseContent);
});



